import React, { useState, useEffect } from "react";
import { Input, message, Upload, Checkbox, Modal } from "antd";
// import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Toast, Image, Mask } from "antd-mobile";
import "./index.css";
import Http from "../../../utils/Http";
import { Spin } from "antd/es";
import { store } from "../../../store";
import Config from "../../../Config";

const BuildPay = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingImg2, setLoadingImg2] = useState(false);
  const [visible, setVisible] = useState(false);
  const [account, setAccount] = useState("222");
  const [idcard, setiIcard] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [tokens, setTokens] = useState("");
  const [IdCardFront, setImgz] = useState("");
  const [IdCardBack, setImgf] = useState("");
  const [ids, setIds] = useState("");
  //补全信息时候禁用
  const [disabled, setDisabled] = useState(false);

  const [dataUpimg, setDataUpimg] = useState(0);
  const [showPhone, setShowPhone] = useState(false);
  const [showInput, setShowInput] = useState(false);
  //开户行地址
  const [site, setSite] = useState("");
  //银行名称
  const [bankName, setBankName] = useState("");
  // 富文本
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [agreement, setAgreement] = useState("");
  const [xieyiShow1, setXieyiShow1] = useState(false);
  const [xieyiShow2, setXieyiShow2] = useState(false);

  useEffect(() => {
    const { App } = store.getState();
    setTokens("Bearer " + App.token);
    getList();
  }, []);
  const clearInput = () => {
    setAccount("");
    setiIcard("");
    setName("");
    setMobile("");
    setImgz("");
    setImgf("");
    setImageUrl("");
    setImageUrl2("");
    setBankName("");
    setSite("");
  };
  const handleCheck = async () => {
    if (datas.every((item) => item.show === true) && xieyiShow1 && xieyiShow2) {
      if (disabled == false) {
        if (loading) {
          return;
        }
        // category 1支付宝 ，2银行卡
        let data = {};
        if (showInput) {
          if (
            !account ||
            !name ||
            // !idcard ||
            // !mobile ||
            // !IdCardFront ||
            // !IdCardBack ||
            !site ||
            !bankName
          ) {
            Toast.show("请输入完整身份信息");
            return;
          }
          // 银行卡
          data = {
            account,
            name,
            category: 2,
            // id_number: idcard,
            // mobile,
            // IdCardFront,
            // IdCardBack,
            account_address: site,
            bank_name: bankName,
          };
          // };
        } else {
          if (
            !account ||
            !name ||
            !idcard ||
            !mobile
            // !IdCardFront ||
            // !IdCardBack
          ) {
            Toast.show("请输入完整身份信息");
            return;
          }
          // 支付宝
          data = {
            account,
            name,
            category: 1,
            id_number: idcard,
            mobile,
            IdCardFront,
            IdCardBack,
          };
        }
        setLoading(true);
        try {
          const res = await Http.postForm(
            "/api/v1/wechat_public/accountAdd",
            data
          );
          if (res.status === 10000) {
            Toast.show("绑定成功");
            setVisible(false);

            props.onFinish && props.onFinish();
          } else {
            Toast.show(res.message);
          }
        } catch (e) {
          Toast.show(e.message);
        } finally {
          setLoading(false);
        }
      } else {
        if (loading) {
          return;
        }
        if (!account || !name || !idcard || !mobile) {
          Toast.show("请输入完整身份信息");
          return;
        }
        setLoading(true);
        try {
          const res = await Http.postForm(
            "/api/v1/wechat_public/addIdCardImg",
            {
              account,
              name,
              id_number: idcard,
              mobile,
              IdCardFront,
              IdCardBack,
              id: ids,
            }
          );
          if (res.status === 10000) {
            Toast.show("绑定成功");
            setVisible(false);

            props.onFinish && props.onFinish();
          } else {
            Toast.show(res.message);
          }
        } catch (e) {
          Toast.show(e.message);
        } finally {
          setLoading(false);
        }
      }
    } else {
      Toast.show("勾选协议后确认");
      return;
    }
  };
  //字典配置项目
  const typeInspect = async () => {
    try {
      const res = await Http.postForm("/api/v1/common/getKeyConfig", {
        key: "business_id_card_check_switch",
      });
      if (res.status === 10000) {
        setDataUpimg(res.data);
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
      Toast.show(e.message);
    }
  };

  const [datas, setDatas] = useState([]);
  const onChanges = (index) => {
    datas[index].show = !datas[index].show;
  };
  // 其他协议勾选
  const xieyiChanges1 = (e) => {
    setXieyiShow1((xieyiShow1) => !xieyiShow1);
  };
  const xieyiChanges2 = (e) => {
    setXieyiShow2((xieyiShow2) => !xieyiShow2);
  };
  const getList = async (id) => {
    try {
      const res = await Http.postForm("/api/v1/common/config");
      if (res?.status === 10000) {
        const data = JSON.parse(res.data.business_flexible_employment_contract);
        const result = data.map((item) => ({
          name: item.name, // 获取 name 属性值
          api: item.api, // 获取 api 属性值
          show: false,
        }));
        setDatas(result);
      } else {
        message.warning(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      // setALoading(false);
    }
  };
  const getUrl = async (item) => {
    try {
      const res = await Http.postForm(item.api);
      if (res?.status === 10000) {
        window.location.href = res.data.url;
      } else {
        message.warning(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      // setALoading(false);
    }
  };
  const show = (res) => {
    clearInput();
    setVisible(true);
    setDisabled(false);
    setShowPhone(false);
    if (res == "alipay") {
      setShowInput(false);
    } else {
      setShowInput(true);
    }
    // typeInspect();
  };
  const imgText = (datas) => {
    clearInput();
    setVisible(true);
    setName(datas.name);
    setAccount(datas.account);
    setiIcard(datas.id_number);
    setMobile(datas.mobile);
    if (datas.mobile == "" || datas.certification_status == 5) {
      setShowPhone(false);
    } else {
      setShowPhone(true);
    }
    if (datas.certification_status == 5) {
      setMobile("");
    }
    setIds(datas.id);
    // if (datas.certification_status === 4 || datas.certification_status == 5) {
    //   setDataUpimg(0);
    // } else {
    //   setDataUpimg(1);
    // }
  };
  const disableds = () => {
    setDisabled(true);
  };

  React.useImperativeHandle(ref, () => {
    return {
      show: show,
      disableds: disableds,
      imgText,
    };
  });
  //图片上传
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.error("文件格式必须为JPG/JPEG");
    }
    const isLt2M = file.size / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("图片大小不要超过3MB");
    }
    return isJpgOrPng && isLt2M;
  };
  const [imageUrl, setImageUrl] = useState();
  const [imageUrl2, setImageUrl2] = useState();
  const handleChange = (info) => {
    console.log(info, "状态");
    if (info.file.status === "uploading") {
      setLoadingImg(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoadingImg(false);
        setImageUrl(url);
      });
      setImgz(info.file.response.data.url);
    }
  };
  //
  const handleChange2 = (info) => {
    console.log(info, "状态");
    if (info.file.status === "uploading") {
      setLoadingImg2(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoadingImg2(false);
        setImageUrl2(url);
      });
      setImgf(info.file.response.data.url);
    }
  };
  // 富文本
  const getAgreementContent = async (id) => {
    try {
      const res = await Http.postForm("/api/v1/article/detail?id=" + id);
      console.log(res);
      if (res?.status === 10000) {
        setAgreement(res.data.content);
      } else {
        message.warning(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      // setALoading(false);
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {/* {loadingImg ? <LoadingOutlined /> : ""} */}
      <div
        style={{
          marginTop: 8,
        }}
      ></div>
    </button>
  );
  const uploadButton2 = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {/* {loadingImg2 ? <LoadingOutlined /> : ""} */}
      <div
        style={{
          marginTop: 8,
        }}
      ></div>
    </button>
  );

  return (
    <Mask visible={visible} onMaskClick={() => setVisible(false)}>
      <div className={showInput ? "buildMask3" : "buildMask2"}>
        <div
          style={{
            display: "flex",
            flex: 3,
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div
            style={{
              fontSize: 18,
              color: "#050303",
              fontWeight: 600,
              marginTop: 10,
            }}
          >
            {showInput ? "绑定银行卡账号" : "绑定支付宝账号"}
          </div>
          {showInput === false ? (
            <div className="upImg">
              <div className="upData">
                <Upload
                  name="file"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  // action="https://quanquan.ningmengxinxi.cn/api/v1/common/wechatPublicFileUpload"
                  action={`${Config.domain}/api/v1/common/wechatPublicFileUpload`}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  headers={{ Authorization: tokens }}
                >
                  {imageUrl ? (
                    <img
                      className="imgBox"
                      src={imageUrl}
                      alt="file"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
                <div className="imgText">上传身份证人像面 </div>
              </div>
              <div className="upData">
                <Upload
                  name="file"
                  listType="picture-card"
                  className="avatar-uploaderB"
                  showUploadList={false}
                  action={`${Config.domain}/api/v1/common/wechatPublicFileUpload`}
                  beforeUpload={beforeUpload}
                  onChange={handleChange2}
                  headers={{ Authorization: tokens }}
                >
                  {imageUrl2 ? (
                    <img
                      className="imgBox"
                      src={imageUrl2}
                      alt="file"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButton2
                  )}
                </Upload>
                <div className="imgText">上传身份证国徽面</div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="姓名"
              disabled={disabled}
              style={{
                fontSize: 14,
                height: 48,
                marginTop: 20,
                borderRadius: 8,
                width: "100%",
                boxShadow: "none",
                borderColor: "#d9d9d9",
              }}
            />

            {/* 银行卡账户 */}
            {showInput ? (
              <div
                style={{
                  // display: "flex",
                  width: "100%",
                  // flexDirection: "column",
                  // gap: "10px",
                }}
              >
                <Input
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  placeholder="银行名称"
                  disabled={disabled}
                  style={{
                    fontSize: 14,
                    height: 48,
                    marginTop: 20,
                    borderRadius: 8,
                    width: "100%",
                    boxShadow: "none",
                    borderColor: "#d9d9d9",
                  }}
                />
                <Input
                  value={site}
                  onChange={(e) => setSite(e.target.value)}
                  placeholder="开户行地址"
                  disabled={disabled}
                  style={{
                    fontSize: 14,
                    height: 48,
                    borderRadius: 8,
                    width: "100%",
                    boxShadow: "none",
                    borderColor: "#d9d9d9",
                    marginTop: 10,
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  // display: "flex",
                  width: "100%",
                  // flexDirection: "column",
                  // gap: "10px",
                }}
              >
                <Input
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="手机号码"
                  disabled={showPhone}
                  style={{
                    fontSize: 14,
                    height: 48,
                    borderRadius: 8,
                    width: "100%",
                    marginTop: 10,
                    boxShadow: "none",
                    borderColor: "#d9d9d9",
                  }}
                />
                <Input
                  value={idcard}
                  onChange={(e) => setiIcard(e.target.value)}
                  placeholder="身份证号码"
                  disabled={disabled}
                  style={{
                    fontSize: 14,
                    height: 48,
                    borderRadius: 8,
                    marginTop: 10,
                    width: "100%",
                    boxShadow: "none",
                    borderColor: "#d9d9d9",
                  }}
                />
              </div>
            )}
            <Input
              value={account}
              onChange={(e) => setAccount(e.target.value)}
              placeholder={showInput ? "银行卡账号" : "支付宝账号"}
              disabled={disabled}
              style={{
                fontSize: 14,
                height: 48,
                borderRadius: 8,
                marginTop: 10,
                boxShadow: "none",
                borderColor: "#d9d9d9",
              }}
            />
          </div>
        </div>
        <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}>
          {datas.map((item, index) => (
            <div
              key={index}
              style={{
                flexWrap: "wrap",
                textAlign: "left",
                color: "#979797",
                fontSize: 12,
              }}
            >
              <Checkbox
                onChange={() => onChanges(index)}
                style={{ paddingRight: 5 }}
              ></Checkbox>
              遵守并同意
              {/* <Link to={agreement}> */}
              <span
                onClick={async () => {
                  await getUrl(item);
                }}
                style={{ color: "#3B82F6", fontSize: 12 }}
              >
                {item.name}
              </span>
              {/* </Link> */}
            </div>
          ))}
          <div
            style={{
              flexWrap: "wrap",
              textAlign: "left",
              color: "#979797",
              fontSize: 12,
            }}
          >
            <Checkbox
              onChange={() => xieyiChanges1()}
              style={{ paddingRight: 5 }}
            ></Checkbox>
            遵守并同意
            {/* <Link to={agreement}> */}
            <span
              onClick={async () => {
                await getAgreementContent(996);
                setIsModalVisible(true);
              }}
              style={{ color: "#3B82F6", fontSize: 12 }}
            >
              《江西大鹅灵活用工协议》
            </span>
            {/* </Link> */}
          </div>
          <div
            style={{
              flexWrap: "wrap",
              textAlign: "left",
              color: "#979797",
              fontSize: 12,
            }}
          >
            <Checkbox
              onChange={() => xieyiChanges2()}
              style={{ paddingRight: 5 }}
            ></Checkbox>
            遵守并同意
            {/* <Link to={agreement}> */}
            <span
              onClick={async () => {
                await getAgreementContent(995);
                setIsModalVisible(true);
              }}
              style={{ color: "#3B82F6", fontSize: 12 }}
            >
              《慧用工灵活用工协议》
            </span>
            {/* </Link> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            // flex: 1,
            height: "70px",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: -9,
            position: "relative",
          }}
        >
          {/* <div
            style={{
              position: "absolute",
              zIndex: 100,
              top: 10,
              color: "rgb(240, 53, 80)",
            }}
          >
            注*：输入的手机号码必须由该身份证注册
          </div> */}

          <Button
            style={{ height: 50, "--border-width": 0, borderRadius: 8 }}
            block
            onClick={() => {
              setVisible(false);
            }}
          >
            <div style={{ color: "#979797", fontSize: 16 }}>取消</div>
          </Button>

          <Button
            style={{ height: 50, "--border-width": 0, borderRadius: 8 }}
            block
            onClick={() => {
              handleCheck();
            }}
          >
            {loading ? (
              <Spin />
            ) : (
              <div style={{ color: "#eb8322", fontSize: 16 }}>确定</div>
            )}
          </Button>
        </div>
        <Modal
          open={isModalVisible}
          mask
          maskClosable
          footer={null}
          onCancel={() => {
            setIsModalVisible(false);
          }}
        >
          <div
            className="modalClass"
            dangerouslySetInnerHTML={{ __html: agreement }}
          ></div>
        </Modal>
      </div>
    </Mask>
  );
});

export default BuildPay;
